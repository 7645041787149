<template>
    <div>
        <h1>Mail-Versand</h1>
        <h2>{{ this.$route.params.eventName }}</h2>
        <table class="table table-sm">
            <thead class="thead-dark">
                <tr>
                    <th style="width: 100px;" scope="col">Timestamp</th>
                    <th>Info</th>
                </tr>
            </thead>
            <tbody>
                <tr v-for="entry in reportEntries" :key="entry.id">
                    <td>{{ entry.timestamp | formatDate }}</td>
                    <td><span :style="getHintColor(entry.success)">{{ entry.name }}</span></td>
                </tr>
            </tbody>
        </table>
    </div>
</template>

<script>
import { APIService } from "../APIService";
import moment from "moment";

const apiService = new APIService();

export default {
    name: "MailStatusView",

    components: {},

    filters: {
        formatDate: function (value) {
            if (value) {
                return moment(value).format("HH:mm.ss");
            }
        }
    },

    data() {
        return {
            reportEntries: []
        };
    },
    methods: {
        doNothing: function () {
            // Do nothing
            console.log("No");
        },
        getHintColor(state) {
            if (state == true) {
                return "color: #000000;";
            } else {
                return "color: #ED0000;";
            }
        },
        getStatus() {
            apiService.sendWorkplanMails(this.$route.params.eventId).then(data => {
                data.entries.forEach(entry => {
                    console.log("Export: " + entry.name)
                });
                this.reportEntries = data.entries;
            })
        }
    },
    mounted() {
        this.getStatus();
    }
};
</script>