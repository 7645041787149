<template>
  <div>
    <h1><router-link
              :to="{ name: 'structure', params: { event: $route.params.event}}"
            >{{ $route.params.event.name }}</router-link>&nbsp;->&nbsp;{{ eventPartName }}
      <img style="cursor: pointer" @click="getEventplan()" src="../assets/pdf-icon.png" width="35pt"/></h1>
    <h2>
      Bereichsgruppen
      <button v-if="this.$route.params.event.state != 'CLOSED'" class="btn btn-primary" @click="addSectionGroup($route.params.eventPart)">+</button>
    </h2>
    <table class="table table-bordered table-hover table-striped">
      <thead class="thead-dark">
        <tr>
          <th scope="col">Name</th>
          <th>gemeldete Helfer</th>
          <th>Jobs</th>
          <th>unbesetzte Jobs</th>
        </tr>
      </thead>
      <tbody>
        <tr v-for="group in sectionGroups" :key="group.id">
          <th scope="row">
            <router-link
              :to="{ name: 'sectionGroup', params: { group: group, partName: eventPartName }}"
            >{{ group.name }}</router-link>&nbsp;
            <b-button class="btn btn-warning" @click="editGroup(group)">o</b-button>&nbsp;
            <b-button
              class="btn btn-danger"
              icon
              v-confirm="{
                        loader: false,
                        ok: dialog => deleteGroup(dialog, group),
                        cancel: doNothing,
                        message: group.name + ' wirklich löschen?'}"
            >X</b-button>            
          </th>
          <td>
            <router-link
              :to="{ name: 'availablePersons', params: { id: $route.params.eventPart.id, group: group, partName: eventPartName }}"
            >{{ workHelperCount[group.id] }}</router-link>
          </td>
          <td>{{ group.jobCount }}</td>
          <td>{{ (group.jobCount - group.assignmentCount) }} </td>
        </tr>
        <tr>
          <th scope="row"><b>Summe</b></th>
          <td></td>
          <td class="sum">{{ totalJobCount }}</td>
          <td class="sum">{{ totalMissingCount }}</td>
        </tr>
      </tbody>
    </table>

    <h2>Helferliste</h2>

    <div>
      <span v-for="person in persons" :key="person.uniqueId">
        <span
          :style="getBgColor(person.assignmentCount)"
        >
          <router-link
            :to="{ name: 'person', params: { id: person.id, name: person.displayName }}"
          ><span class="text-dark">{{ person.displayName }}</span></router-link>
        </span>
      </span>
    </div>
  </div>
</template>

<script>
import { APIService } from "../APIService";

const apiService = new APIService();

export default {
  name: "EventPart",

  components: {},

  data() {
    return {
      eventPartName: "",
      workHelperCount: [],
      sectionGroups: [],
      persons: []
    };
  },
  computed: {
    totalJobCount: function() {
      var totalCount = 0;
      this.sectionGroups.forEach(group => (totalCount += group.jobCount));
      return totalCount;
    },
    totalMissingCount: function() {
      var totalCount = 0;
      this.sectionGroups.forEach(group => (totalCount += (group.jobCount - group.assignmentCount)));
      return totalCount;
    }    
  },
  methods: {
    doNothing: function() {
      // Do nothing
    },    
    getBgColor(count) {
      var text = "padding:5px; margin:4px; display: inline-block; text-overflow: ellipsis; overflow: hidden; white-space: nowrap; max-width:230px; min-width:230px; border-style: none;";
      if(count > 1) {
        return text + " background-color:#ffaa23;";
      } else if(count > 0) {
        return text + " background-color:#2fd92d;";
      } else {
        return text + " background-color:#e3e6eb;";
      }
    },
    deleteGroup(dialog, group) {
      apiService.deleteSectionGroup(group.eventPart.id, group).then(response => {
        if (response.status == 200) {
          const index = this.sectionGroups.findIndex(post => post.id === group.id); // find the group index
          if (~index)
            // if the post exists in array
            this.sectionGroups.splice(index, 1); //delete the group
        }
      });
      dialog.close();
    },    
    editGroup(group) {
      console.log(group);
      this.$router.push({
        name: "editSectionGroup",
        params: {
          group: group,
          eventPart: this.$route.params.eventPart,
          event: this.$route.params.event
        }
      });
    },
    addSectionGroup(eventPart) {
      this.$router.push({
        name: "editSectionGroup",
        params: { eventPart: eventPart, event: this.$route.params.event }
      });
    },
    getEventplan() {
      apiService.getEventPartPlanExport(1, this.$route.params.eventPart.id, this.eventPartName);
    },  
    getData() {
      apiService.getSectionGroups(this.$route.params.eventPart.id).then(data => {
        this.sectionGroups = data;

        this.sectionGroups.forEach(group => {
          apiService
            .getPersons(this.$route.params.eventPart.id, group.workType, false, false)
            .then(data2 => {
              this.$data.workHelperCount[group.id] = data2.length;
              this.$forceUpdate();
            });
        });
      });
      apiService.getPersonsCount(this.$route.params.eventPart.id).then(data => {
        this.persons = data;
      });
    }
  },
  mounted() {
    apiService.getEventPart(this.$route.params.eventPart.id).then(data => {
      this.eventPartName = data.name;
    });
    this.getData();
  }
};
</script>

<style scoped>
td.sum {
  font-weight: bold;
}
</style>