<template>
  <div>
    <h1>
      <router-link :to="{ name: 'eventPart', params: { eventPart: $route.params.group.eventPart, event: $route.params.group.eventPart.event } }">{{
        $route.params.group.eventPart.name }}</router-link>
      -> {{ $route.params.group.name }}
      &nbsp;<img style="cursor: pointer" @click="getSectionplan()" src="../assets/pdf-icon.png" width="35pt" />
    </h1>

    <b-modal ref="modal-1" size="xl" centered :title="activeTitle" @ok="handleModalSave">
      <p-selection v-on:childToParent="onModalChange" v-bind:section="activeSection" v-bind:position="activePosition"
        v-bind:shift="activeShift" v-bind:shiftAssignment="activeAssignment"
        v-bind:eventPart="$route.params.group.eventPart"></p-selection>
    </b-modal>

    <table class="table table-bordered table-hover table-striped">
      <thead class="thead-dark">
        <tr>
          <th scope="col">
            Bereiche
            <button class="btn btn-primary" @click="addSection($route.params.group)">+ Bereich</button>&nbsp;
            <b-form-checkbox id="checkbox-1" v-model="showHelpers" name="checkbox-1" value="true"
              unchecked-value="false" switch>Helfer-Zuteilung</b-form-checkbox>
          </th>
        </tr>
      </thead>
      <tbody>
        <tr v-for="section in sections" :key="section.id">
          <th scope="row">
            <b>{{ section.name }}</b>
            <span v-if="section.responsible">&nbsp;({{ section.responsible.firstName }} {{ section.responsible.lastName
              }})</span>
            <span v-else>&nbsp;(<i>unbesetzt</i>)</span>
            &nbsp;
            <button v-show="showHelpers === 'false'" class="btn btn-primary" @click="addPosition(section)">+
              Position</button>&nbsp;
            <b-button class="btn btn-warning" @click="editSection(section)">o</b-button>&nbsp;
            <b-button class="btn btn-danger" icon v-show="showHelpers === 'false'" v-confirm="{
              loader: false,
              ok: dialog => deleteSection(dialog, section),
              cancel: doNothing,
              message: section.name + ' wirklich löschen?'
            }">X</b-button>
            <table class="table table-bordered table-hover table-striped table-sm">
              <thead class="thead-light">
                <tr>
                  <th scope="col">
                    Name
                    <button v-show="showHelpers === 'false'" class="btn btn-primary" @click="addShift(section)">+
                      Schicht</button>
                  </th>
                  <th v-for="(column, index) in getShifts(section)" :key="index">
                    {{ column.name }}&nbsp;Uhr&nbsp;
                    <button class="btn btn-warning" @click="editShift(section, column)">o</button>&nbsp;
                    <button v-show="showHelpers === 'false'" v-if="getShifts(section).length > 1" class="btn btn-danger"
                      v-confirm="{
                        loader: false,
                        ok: dialog => deleteShift(dialog, section, column),
                        cancel: doNothing,
                        message: column.name + ' wirklich löschen?'
                      }">X</button>
                  </th>
                </tr>
              </thead>
              <tbody>
                <tr v-for="position in section.positions" :key="position.id">
                  <th scope="row">
                    {{ position.name }}&nbsp;
                    <button v-if="position.type != 'RESPONSIBLE'" class="btn btn-warning"
                      @click="editPosition(section, position)">o</button>&nbsp;
                    <button v-show="showHelpers === 'false'" v-if="position.type != 'RESPONSIBLE'"
                      class="btn btn-danger" v-confirm="{
                        loader: false,
                        ok: dialog => deletePosition(dialog, section, position),
                        cancel: doNothing,
                        message: position.name + ' wirklich löschen?'
                      }">X</button>
                  </th>
                  <td v-for="(column, index) in getShifts(section)" :key="index">
                    <input type="text" v-show="showHelpers === 'false'" v-model="shiftCounts[position.id][column.id]"
                      @change="shiftHandler(position.id, column.id)" />&nbsp;
                    <span v-bind:style="getStyle(position.id, column.id)" v-show="showHelpers === 'true'">{{
                      assignmentCounts[position.id][column.id] }} / {{ shiftCounts[position.id][column.id]
                      }}</span>&nbsp;
                    <b-button v-show="showHelpers === 'true' && shiftCounts[position.id][column.id] > 0"
                      @click="showModal(section, position, column)">Helfer</b-button>
                  </td>
                </tr>
              </tbody>
            </table>
          </th>
        </tr>
      </tbody>
    </table>
  </div>
</template>

<script>
import { APIService } from "../APIService";
import PersonSelection from "../components/PersonSelection.vue";
import Vue from "vue";

const apiService = new APIService();

export default {
  name: "SectionGroup",

  components: {
    pSelection: PersonSelection
  },

  data() {
    return {
      okStyle: {
        backgroundColor: "green",
        padding: "6px",
        borderRadius: "35px"
      },
      greyStyle: {
        backgroundColor: "#C3C6CB",
        padding: "6px",
        borderRadius: "35px"
      },
      proStyle: {
        backgroundColor: "orange",
        padding: "6px",
        borderRadius: "35px"
      },
      badStyle: {
        backgroundColor: "#FF4B3A",
        padding: "6px",
        borderRadius: "35px"
      },
      groupName: "",
      sections: [],
      shiftCounts: [],
      assignmentCounts: [],
      shiftAssignments: [],
      activeSection: {},
      activePosition: {},
      activeShift: {},
      selectedPersons: [],
      originalPersons: [],
      activeTitle: "",
      activeAssignment: {},
      showHelpers: "true"
    };
  },

  methods: {
    doNothing: function () {
      // Do nothing
      console.log("No");
    },
    getStyle(positionId, shiftId) {
      if (this.assignmentCounts[positionId][shiftId] == 0) {
        if (this.shiftCounts[positionId][shiftId] == 0) {
          return this.greyStyle;
        } else {
          return this.badStyle;
        }
      } else {
        if (
          this.assignmentCounts[positionId][shiftId] <
          this.shiftCounts[positionId][shiftId]
        ) {
          return this.proStyle;
        } else {
          return this.okStyle;
        }
      }
    },
    showModal(section, position, shift) {
      this.activeSection = section;
      this.activePosition = position;
      this.activeShift = shift;
      this.activeTitle =
        section.name + ": " + position.name + " (" + shift.name + ")";
      this.activeAssignment = this.shiftAssignments[position.id][shift.id];
      this.$refs["modal-1"].show();
    },
    onModalChange(originalList, updatedList) {
      console.log("Child changed!");
      console.log(updatedList);
      this.originalPersons = originalList;
      this.selectedPersons = updatedList;
    },
    handleModalSave() {
      console.log("Save");

      var self = this;
      const shiftAssignment = this.shiftAssignments[this.activePosition.id][
        this.activeShift.id
      ];
      if (shiftAssignment != null) {
        this.selectedPersons.forEach(person => {
          console.log("Checking person for addition " + person.id);
          const existingPerson = this.originalPersons.find(
            orgPerson => orgPerson.id == person.id
          );
          if (existingPerson == null) {
            console.log("  to be added: " + person.id);
            // no existing entry found -> issue POST
            const model = {
              person: person
            };
            apiService
              .createAssignment(model, shiftAssignment.id)
              .then(function (response) {
                if (typeof response === "undefined" || response === null) {
                  console.log(
                    "Da ist was schief gelaufen. No response object."
                  );
                } else {
                  /*if (response.status == 200) {
                  }*/
                  console.log(
                    "Status: " + response.status + "::" + response.statusText
                  );

                  self.loadAssignmentData();
                }
              });
          } else {
            console.log("  already existing: " + existingPerson.id);
          }
        });
        this.originalPersons.forEach(person => {
          console.log("Checking person for deletion " + person.id);
          const existingPerson = this.selectedPersons.find(
            orgPerson => orgPerson.id == person.id
          );
          if (existingPerson == null) {
            console.log("  to be deleted: " + person.id);
            // no existing entry in original list could be found -> issue DELETE
            apiService
              .deleteAssignment(shiftAssignment.id, person.id)
              .then(function (response) {
                if (typeof response === "undefined" || response === null) {
                  console.log(
                    "Da ist was schief gelaufen. No response object."
                  );
                } else {
                  /*if (response.status == 200) {
                  }*/
                  console.log(
                    "Status: " + response.status + "::" + response.statusText
                  );

                  // hack to force Vue to update the status color & number of this "button"
                  if (self.assignmentCounts[self.activePosition.id][shiftAssignment.shift.id] == 1) {
                    Vue.set(self.assignmentCounts[self.activePosition.id], shiftAssignment.shift.id, 0);
                  }
                  self.loadAssignmentData();
                }
              });
          } else {
            console.log("  already existing: " + existingPerson.id);
          }
        });
      } else {
        console.log("Inconsistent data model. No matching shift assignment.");
      }

      this.originalPersons = [];
      this.selectedPersons = [];
    },
    addSection(sectionGroup) {
      console.log(sectionGroup);
      this.$router.push({
        name: "editSection",
        params: {
          sectionGroup: sectionGroup
        }
      });
    },
    editSection(section) {
      console.log(section);
      this.$router.push({
        name: "editSection",
        params: {
          sectionGroup: section.group,
          section: section
        }
      });
    },
    deleteSection(dialog, section) {
      apiService.deleteSection(section.group.id, section).then(response => {
        if (response.status == 200) {
          const index = this.sections.findIndex(post => post.id === section.id); // find the section index
          if (~index)
            // if the post exists in array
            this.sections.splice(index, 1); //delete the section
        }
      });
      dialog.close();
    },
    addShift(section) {
      console.log(section);
      this.$router.push({
        name: "editShift",
        params: {
          section: section
        }
      });
    },
    editShift(section, shift) {
      console.log(shift);
      this.$router.push({
        name: "editShift",
        params: {
          section: section,
          shift: shift
        }
      });
    },
    shiftHandler(positionId, shiftId) {
      console.log("Handler: " + this.shiftCounts[positionId][shiftId]);
      apiService
        .updateShiftCount(positionId, shiftId, {
          shift: shiftId,
          count: this.shiftCounts[positionId][shiftId]
        })
        .then(function (response) {
          if (typeof response === "undefined" || response === null) {
            console.log("Da ist was schief gelaufen. No response object.");
          } else {
            /*if (response.status == 200) {
            }*/
            console.log(
              "Status: " + response.status + "::" + response.statusText
            );
          }
        });
    },
    getShifts(section) {
      return section.shifts;
    },
    deleteShift(dialog, section, shift) {
      console.log("Delete: " + shift.name);
      apiService.deleteShift(section.id, shift).then(response => {
        if (response.status == 200) {
          const index = section.shifts.findIndex(post => post.id === shift.id); // find the post index
          if (~index)
            // if the post exists in array
            section.shifts.splice(index, 1); //delete the post
        } else if (response.status == 422) {
          alert({
            title: "Your title",
            message: "Your message1",
            okButtonText: "Your OK button text"
          }).then(() => {
            console.log("Alert dialog closed");
          });
        } else {
          alert({
            title: "Your title",
            message: "Your message2",
            okButtonText: "Your OK button text"
          }).then(() => {
            console.log("Alert dialog closed");
          });
        }
      });
      dialog.close();
    },
    addPosition(section) {
      this.$router.push({
        name: "editPosition",
        params: {
          section: section
        }
      });
    },
    editPosition(section, position) {
      console.log(position);
      this.$router.push({
        name: "editPosition",
        params: {
          section: section,
          position: position
        }
      });
    },
    deletePosition(dialog, section, position) {
      console.log("Delete: " + position.name);
      apiService.deletePosition(section.id, position).then(response => {
        if (response.status == 200) {
          const index = section.positions.findIndex(
            post => post.id === position.id
          ); // find the post index
          if (~index)
            // if the post exists in array
            section.positions.splice(index, 1); //delete the post
        }
      });
      dialog.close();
    },
    getSectionplan() {
      apiService.getSectionGroupExport(this.$route.params.group.eventPart.name, this.$route.params.group.id, this.$route.params.group.name);
    },
    getData() {
      apiService.getSections(this.$route.params.group.id).then(data => {
        this.sections = data;
        // initialize shifts person count array
        this.sections.forEach(section => {
          section.positions.forEach(position => {
            this.shiftCounts[position.id] = [];
            this.shiftAssignments[position.id] = [];
            this.assignmentCounts[position.id] = [];
            section.shifts.forEach(shift => {
              this.shiftCounts[position.id][shift.id] = 0;
              const assignmentItem = position.shiftAssignments.find(
                item => item.shift.id == shift.id
              );
              this.assignmentCounts[position.id][shift.id] = 0;
              if (assignmentItem != null) {
                this.shiftCounts[position.id][shift.id] = assignmentItem.count;
                this.shiftAssignments[position.id][shift.id] = assignmentItem;
              }
            });
          });
        });

        this.loadAssignmentData();
      });
    },
    loadAssignmentData() {
      //const self = this;
      apiService
        .getAssignmentsByGroup(
          this.$route.params.group.eventPart.id,
          this.$route.params.group.id
        )
        .then(data => {
          console.log("Assignment-Item-Count: " + data.length);
          data.forEach(item => {
            //console.log("Position: " + item[0] + ", Shift: " + item[1] + ": " + item[2]);
            //this.assignmentCounts[item[0]][item[1]] = item[2];
            /******************
             * item[0] -> positionId
             * item[1] -> shiftId
             * item[2] -> assignment count
             ******************/
            Vue.set(this.assignmentCounts[item[0]], item[1], item[2]);
            Vue.set(
              this.assignmentCounts,
              item[0],
              this.assignmentCounts[item[0]]
            );
          });
          // self.$forceUpdate();
        });
    }
  },
  mounted() {
    this.getData();
  }
};
</script>