import { render, staticRenderFns } from "./EditPosition.vue?vue&type=template&id=be3a988a&scoped=true&"
import script from "./EditPosition.vue?vue&type=script&lang=js&"
export * from "./EditPosition.vue?vue&type=script&lang=js&"
import style0 from "./EditPosition.vue?vue&type=style&index=0&id=be3a988a&prod&scoped=true&lang=css&"


/* normalize component */
import normalizer from "!../../../node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "be3a988a",
  null
  
)

export default component.exports